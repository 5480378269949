import http from './http'

export const wxPay = async (orderId: string, level: string) => {
  const result: any = await http.request({
    url: '/wxpay/toPay',
    method: 'POST',
    data: {
      orderId,
      level,
    },
  })

  const { code, body } = result

  if (code !== '00000000') {
    return new Error('系统异常，请重试')
  }

  return body
}

export const aliPay = async (orderId: string, level: string) => {
  const result: any = await http.request({
    url: '/alipay/toPay',
    method: 'POST',
    data: {
      orderId,
      level,
    },
  })

  const { code, body } = result

  if (code !== '00000000') {
    return new Error('系统异常，请重试')
  }

  return body
}
