import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PageIndex from './pages/index'
import PageInfo from './pages/info'
import PagePay from './pages/pay'
import PageQA from './pages/qa'
import PageResult from './pages/result'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageIndex />} />
        <Route path="/qa" element={<PageQA />} />
        <Route path="/pay" element={<PagePay />} />
        <Route path="/result" element={<PageResult />} />
        <Route path="/info" element={<PageInfo />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
