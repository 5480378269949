import { dateFormat } from 'auto-libs'
import { Button, Layout, Radio, Toast } from 'auto-ui'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'

const ages = [
  '5.5岁',
  '6岁',
  '6.5岁',
  '7岁',
  '7.5岁',
  '8岁',
  '8.5岁',
  '9岁',
  '9.5岁',
  '10岁',
  '10.5岁',
  '11岁',
  '11.5岁',
  '12岁',
  '12.5岁',
  '13岁',
  '13.5岁',
  '14岁',
  '14.5岁',
  '15岁',
  '15.5岁',
  '16岁',
  '16.5岁',
  '17岁',
  '20-29岁',
  '30-39岁',
  '40-49岁',
  '50-59岁',
  '60-69岁',
  '70及以岁',
]

const scoreMap = new Map([
  ['5.5岁', [34, 29, 25, 16, 13, 12, 9]],
  ['6岁', [36, 31, 25, 17, 13, 12, 9]],
  ['6.5岁', [37, 31, 25, 18, 13, 12, 10]],
  ['7岁', [43, 36, 25, 19, 13, 12, 10]],
  ['7.5岁', [44, 38, 31, 21, 13, 12, 10]],
  ['8岁', [44, 39, 31, 23, 15, 13, 10]],
  ['8.5岁', [45, 40, 33, 29, 20, 14, 12]],
  ['9岁', [47, 43, 37, 33, 25, 14, 12]],
  ['9.5岁', [50, 47, 39, 35, 27, 17, 13]],
  ['10岁', [50, 48, 42, 35, 27, 17, 13]],
  ['10.5岁', [50, 49, 42, 39, 32, 25, 18]],
  ['11岁', [52, 50, 43, 39, 33, 25, 19]],
  ['11.5岁', [53, 50, 45, 42, 35, 25, 19]],
  ['12岁', [53, 50, 46, 42, 37, 27, 21]],
  ['12.5岁', [53, 52, 50, 45, 40, 33, 28]],
  ['13岁', [53, 52, 50, 45, 40, 35, 30]],
  ['13.5岁', [54, 52, 50, 46, 42, 35, 32]],
  ['14岁', [55, 52, 50, 48, 43, 36, 34]],
  ['14.5岁', [55, 53, 51, 48, 43, 36, 34]],
  ['15岁', [57, 54, 51, 48, 43, 36, 34]],
  ['15.5岁', [57, 55, 52, 49, 43, 41, 34]],
  ['16岁', [57, 56, 53, 49, 44, 41, 36]],
  ['16.5岁', [57, 56, 53, 49, 45, 41, 37]],
  ['17岁', [58, 57, 55, 52, 47, 40, 37]],
  ['20-29岁', [57, 56, 54, 50, 44, 38, 33]],
  ['30-39岁', [57, 55, 52, 48, 34, 37, 28]],
  ['40-49岁', [57, 54, 50, 47, 41, 31, 28]],
  ['50-59岁', [54, 52, 48, 42, 34, 24, 21]],
  ['60-69岁', [54, 52, 46, 37, 30, 22, 19]],
  ['70及以岁', [52, 49, 44, 33, 26, 18, 17]],
])

const PageIndex = () => {
  const [age, setAge] = useState('')
  const [gender, setGender] = useState(1) // 1: 男 0：女性
  const navigateHook = useNavigate()
  const location: any = useLocation()
  const orderId = location.state.orderId

  const onAgeChange = (index: string) => {
    setAge(index)
  }

  const getLevel = () => {
    const getScoreRange = scoreMap.get(age) || []
    const score = location.state.score
    let level = 7

    for (let i = 0; i < getScoreRange.length; i++) {
      if (score >= getScoreRange[i]) {
        level = i + 1
        break
      }
    }

    return level
  }

  const onGenderChange = (v: number) => {
    setGender(v)
  }

  const onGO = () => {
    if (!age) {
      Toast('请选择年龄')
      return
    }

    const level = getLevel()

    navigateHook('/pay', {
      state: {
        level,
        gender,
        age,
        finishTime: dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        orderId,
      },
    })
  }

  return (
    <Layout className="page-info">
      <Layout.Body className="body">
        <div className="title">
          请务必选择<span>真实年龄</span>，这将决定本次评测的准确性
        </div>

        <div className="info">
          <div className="item">
            <div className="name">性别</div>
            <div className="value">
              <Radio checked={gender === 1} onChange={() => onGenderChange(1)} text="男" />
              <Radio checked={gender === 0} onChange={() => onGenderChange(0)} text="女" />
            </div>
          </div>

          <div className="item">
            <div className="name">年龄</div>
            <div className="value">请选择如下年龄</div>
          </div>

          <div className="item">
            <div className="ages">
              {ages.map((item, index) => {
                return (
                  <div
                    className={`age ${item === age ? 'checked' : ''}`}
                    key={index}
                    onClick={() => onAgeChange(item)}
                  >
                    {item}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <Button onClick={onGO}>继续</Button>
      </Layout.Body>
    </Layout>
  )
}

export default PageIndex
