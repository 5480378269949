import { Button, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { wxPay } from '../../utils/pay'
import { getIQStartTime } from '../../utils/storage'
import './style.scss'

const PageIndex = () => {
  // const [alipayForm, setAlipayForm] = useState<any>(null)
  const locationHook = useLocation()
  const [totalTime, setTotalTime] = useState('')

  const locationState: any = locationHook.state || {}
  const { gender, age, level, finishTime, orderId } = locationState

  const onWechatPay = async () => {
    try {
      Loading()
      const result = await wxPay(orderId, level)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  // const onAlipay = async () => {
  //   try {
  //     Loading()
  //     const result = await aliPay(orderId, level)

  //     setAlipayForm(result)

  //     setTimeout(() => {
  //       document.forms[0].submit()
  //     }, 100)
  //   } catch (error) {
  //     Toast('系统异常，请重试')
  //   } finally {
  //     Loading.hide()
  //   }
  // }

  useEffect(() => {
    const start = getIQStartTime()
    const now = new Date()
    const endTime = now.getTime()
    const startTime = start ? Number(start) : now.getTime()
    const offset = Math.floor((endTime - startTime) / 1000)
    let dateStr = ''

    const hh = Math.floor(offset / 3600)
    if (hh > 0) {
      dateStr += hh + '小时'
    }

    const mm = Math.floor((offset % 3600) / 60)

    if (mm > 0) {
      dateStr += mm + '分'
    }

    const ss = offset % 60

    if (ss > 0) {
      dateStr += ss + '秒'
    }

    setTotalTime(dateStr)
  }, [])

  return (
    <Layout className="page-pay">
      <Layout.Body className="body">
        <div className="pane">
          <h1>瑞文标准智力测评【评测报告</h1>
          <h6>你有多聪明？想知道自己智商有多高吗？</h6>

          <div className="info">
            <div className="item">
              <div className="name">答题数</div>
              <div className="value">60题</div>
            </div>
            <div className="item">
              <div className="name">用时</div>
              <div className="value">{totalTime}</div>
            </div>
            <div className="item">
              <div className="name">完成时间</div>
              <div className="value">{finishTime}</div>
            </div>
            <div className="item">
              <div className="name">性别</div>
              <div className="value female">{gender === 1 ? '男' : '女'}</div>
            </div>
            <div className="item">
              <div className="name">年龄</div>
              <div className="value">{age}</div>
            </div>
          </div>
        </div>

        <div className="pane">
          <p className="b">您的专属测评报告已生成</p>
          <p className="b">支付完成后立即查看测评结果</p>
          <p>
            已有<span>234867</span>人参与测试并查看了报告
          </p>
          <p>点击下面按钮支付 ↓↓↓</p>
        </div>

        <div className="pay-button">
          <Button className="btn wechat" onClick={onWechatPay}>
            <span className="icon" />
            微信支付
          </Button>
          {/* <Button className="btn alipay" onClick={onAlipay}>
            <span className="icon" />
            支付宝支付
          </Button> */}
        </div>
      </Layout.Body>

      {/* <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      /> */}
    </Layout>
  )
}

export default PageIndex
