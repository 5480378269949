const IQData = {
  l1: {
    rwbz: '95%',
    level: '一级',
    levelDesc: '智力优秀',
    desc: '<p>根据您的测评分值，您属于智力优秀的水平,您在生活中一定是严格要求自己的人，请多注意保持。平时多注意训练,保持住自己的智力水平很重要。您的智商已经属于超出大多数人的水平。先天的优势重要，后天的努力也很重要，您的智商给了您一个好的基础，当时也不能忘了后天的努力。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [120, 145],
  },
  l2: {
    rwbz: '90%',
    level: '二级',
    levelDesc: '智力良好',
    desc: '<p>您的智商已经属于超出大多数人的水平。先天的优势重要，后天的努力也很重要，您的智商给了您一个好的基础，当时也不能忘了后天的努力。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [110, 119],
  },
  l3: {
    rwbz: '75%',
    level: '三级',
    levelDesc: '智力中等',
    desc: '<p>根据您的测评分值，你属于智力中等的水平，平时多注意训练能增加自己的智力水平。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [100, 109],
  },
  l4: {
    rwbz: '50%',
    level: '四级',
    levelDesc: '智力普通',
    desc: '<p>根据你的测评分值，你属于智力普通的水平，您还需要通过训练提供自己的智力水平。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [90, 99],
  },

  l5: {
    rwbz: '25%',
    level: '五级',
    levelDesc: '智力中下',
    desc: '<p>根据你的测评分值，你属于智力中下的水平。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [80, 89],
  },
  l6: {
    rwbz: '10%',
    level: '六级',
    levelDesc: '智力低下',
    desc: '<p>根据你的测评分值，你属于智力中下的水平。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [71, 79],
  },
  l7: {
    rwbz: '5%',
    level: '七级',
    levelDesc: '智力存在缺陷',
    desc: '<p>根据你的测评分值，你属于<em>智力存在缺陷</em>。</p><p>通常智商较低的人在以下地方会有所欠缺：无确定的目标，也不打算付诸实践；严重依赖他人；</p><p>处理人际关系的能力较差；应对焦虑能力差；无责任感，爱抱怨；</p><p>你常常不能控制自己，你极易被自己的情绪所影响。</p><p>很多时候，你容易被击怒、动火、发脾气，这是非常危险的信号——你的事业可能会毁于你的急躁，对于此，最好的解决办法是能够给不好的东西一个好的解释，保持头脑冷静，使自己心情开朗，正如富兰克林所说：“任何人生气都是有理的，但很少有令人信服的理由。</p>',
    IQRange: [30, 70],
  },
}

export default IQData
